<template>
    <master-layout>
    <div class="WatFocusPreview">
        <div v-if="!showTask" class="WatFocusPreviousInfo">
            <p class="WatFocusPreviousInfo--Type">WATChanceFocus</p>
            <div class="WatFocusPreviousInfo--Info">
                <h1>{{ watFocus.title }}</h1>
                <div class="WatFocusPreviousInfo--Date">
                    <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.0005 38H6.00049C4.93962 38 3.92221 37.5786 3.17206 36.8284C2.42192 36.0783 2.00049 35.0609 2.00049 34V10C2.00049 8.93913 2.42192 7.92172 3.17206 7.17157C3.92221 6.42143 4.93962 6 6.00049 6H30.0005C31.0614 6 32.0788 6.42143 32.8289 7.17157C33.5791 7.92172 34.0005 8.93913 34.0005 10V16M26.0005 2V10M10.0005 2V10M2.00049 18H22.0005M30.0005 29V32L31.0005 33M22.0005 32C22.0005 34.1217 22.8433 36.1566 24.3436 37.6569C25.8439 39.1571 27.8788 40 30.0005 40C32.1222 40 34.1571 39.1571 35.6573 37.6569C37.1576 36.1566 38.0005 34.1217 38.0005 32C38.0005 29.8783 37.1576 27.8434 35.6573 26.3431C34.1571 24.8429 32.1222 24 30.0005 24C27.8788 24 25.8439 24.8429 24.3436 26.3431C22.8433 27.8434 22.0005 29.8783 22.0005 32Z" stroke="url(#paint0_linear_4210_11086)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                        <defs>
                        <linearGradient id="paint0_linear_4210_11086" x1="2.00049" y1="21" x2="38.0005" y2="21" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FF1564"/>
                        <stop offset="1" stop-color="#FD7328"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    {{ moment(watFocus.slot.dateFrom).format('D [de] MMMM [a las] h:mm A') }}
                </div>
                <p>{{ $t('wat_focus_preview_info') }}</p>
            </div>
            <hr>
            <div class="WatFocusPreviousInfo--Elements">
                <h2>{{ $t('wat_focus_preview_test_title') }}</h2>
                <p v-html="$t('wat_focus_preview_test_text')"></p>
                <h2>{{ $t('wat_focus_preview_enviroment_title') }}</h2>
                <p v-html="$t('wat_focus_preview_enviroment_text')"></p>
                <h2>{{ $t('wat_focus_preview_lightning_title') }}</h2>
                <p v-html="$t('wat_focus_preview_lightning_text')"></p>
            </div>
            <div class="WatFocusPreviousInfo--Actions">
                <button v-on:click="gotFocusTask()">{{ $t('wat_focus_preview_access') }}</button>
                <button v-on:click="cancel()">{{ $t('wat_focus_preview_deny') }}</button>
             </div>
        </div>
        <WatFocusTask 
            :testTitle="watFocus.title" 
            :isWatFocusPreview="true"
            :showScreenShare="watFocus.screenShare" 
            :isNativePlatform="isNativePlatform"
            :isAndroid="isAndroid"
            v-if="showTask" 
            v-on:save-connectivity="saveConnectivity($event)"/>
        <div class="WatFocusPreview--Chat" v-if="showChat">
            <Chat :name="nickname" :isUser="true" :title="$t('videochat_chat_activity')" :messages="focusChatMessages" v-on:send-message="sendMessage($event)" :isWatFocus="true"/>
        </div>
        <div class="WatFocusPreview--ChatIcon" v-on:click="showChat = !showChat">
            <span v-show="chatNotifBadge > 0">{{ chatNotifBadge }}</span>
            <i class="far fa-comments"></i>
            <p>CHAT</p>
        </div>
    </div>
    </master-layout>
</template>

<script>
import store from '@/store';
import moment from 'moment-timezone'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import WatFocusTask from '~/components/Execution/WatFocusTask.vue'
import Chat from '~/components/WatFocus/Chat.vue'
import testsStore from '@/store/tests.store';
import authStore from '@/store/auth.store';
import Pusher from 'pusher-js';
import { Capacitor } from '@capacitor/core'
import  { WAT_FOCUS_PUT_MESSAGE, ADD_FOCUS_CHAT_MESSAGE, FETCH_WAT_FOCUS_CHAT_MESSAGES } from '~/store/watfocus.store'
export default defineComponent({
    name: 'WatFocusPreview',
    components:{
        WatFocusTask,
        Chat
    },
    props:{
        isPublic:{
            type: Boolean,
            default: false
        }
    },
    computed:{
        ...mapGetters([
        'focusChatMessages'
        ])
    },
    data(){
        return{
            moment: moment,
            watFocus:{
                title:'Lanzamiento nueva APP',
                slot:{
                    dateFrom: new Date()
                }
            },
            showTask:false,
            chatPusher: null,
            nickname:authStore.state.userData.nickname,
            showChat:false,
            chatNotifBadge:0,
            isNativePlatform: Capacitor.isNativePlatform(),
            isAndroid: Capacitor.getPlatform() === 'android'
        }
    },
    methods:{
        gotFocusTask(){
            this.showTask = true
        },
        cancel(){
            this.$router.push({name: 'tests.index'})
        },
        async saveConnectivity(event){
            await store.dispatch("SAVE_WAT_FOCUS_PREVIOUS_CONNECTIVITY",{data:event, id: this.$route.params.id, slotHash: this.$route.params.slotHash, isPublic:this.isPublic, visitorToken: this.$route.params.auth})
            this.showTask = false
            this.chatPusher.disconnect();
            if(this.isPublic){
                this.$router.push({name:'focusPublic', props:{
                    id:this.$route.params.id, 
                    slotHash:this.$route.params.slotHash,
                    auth: this.$route.params.auth
                }})
            }
            else {
                this.$router.push({name:'focus', props:{
                    id:this.$route.params.id, 
                    slotHash:this.$route.params.slotHash
                }})
            }
    
        },
        fetchWatFocus(){
            testsStore.getWatFocus(this.$route.params.id, this.$route.params.slotHash)
            .then((data) => {
                this.watFocus = data
            })
        },
        fetchPublicTester(){
            store.dispatch("FETCH_FOCUS_TESTER",{visitorToken: this.$route.params.auth,id: this.$route.params.id, slotHash: this.$route.params.slotHash})
        },
        
        subscribeChat(){
            Pusher.logToConsole = false;
            this.chatPusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
                cluster: 'eu',
                forceTLS: true,
            });

            this.chatPusher.subscribe('chat-' + this.$route.params.id + '-slot-' + this.$route.params.slotHash)
            this.chatPusher.bind('chat-message', this.handleReceivedMessageVideochat)
        },
        async sendMessage(event){
            event.isModerator = this.isModerator
            const data = {
                id: this.$route.params.id,
                slotHash: this.$route.params.slotHash,
                isModerator: this.isModerator,
                data:event
            }
            await store.dispatch(WAT_FOCUS_PUT_MESSAGE,data)
        },
        handleReceivedMessageVideochat(message){
                if(message.isModerator) this.chatNotifBadge ++
                message.creationDate = moment(message.creationDate).format('YYYY-MM-DDTHH:mm:ss');
                message.creationDate += '.307+0000'
                store.commit(ADD_FOCUS_CHAT_MESSAGE, message)
        },
        fetchChatMessages(){
            store.dispatch(FETCH_WAT_FOCUS_CHAT_MESSAGES,{id:this.$route.params.id, slotHash: this.$route.params.slotHash})
        },
    },
    created(){
        if(this.isPublic){
            this.showTask = true
            this.fetchPublicTester()
        }
    },
    mounted(){
        this.fetchChatMessages()
        this.fetchWatFocus()
        this.subscribeChat()
    }
})
</script>

<style lang="scss">
.WatFocusPreviousInfo{
    padding: 79px 32px 79px 32px;
    &--Type{
        font-family: Lato;
        font-size: 16px;
        font-weight: 700;
        background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin:0;
    }
    &--Date{
        margin:20px 0;
        border-radius: 4px;
        border: 1px solid #FF1564;
        background: linear-gradient(rgba(255, 21, 100, 0.1),rgba(253, 115, 40, 0.1));
        font-family: Lato;
        font-size: 20px;
        padding: 24px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        gap: 15px;
        svg{
            height: 35px;
        }
    }
    &--Info{
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        color: #626262;
    }
    &--Elements{
        display: flex;
        flex-direction: column;
        p{
            font-family: Lato;
            font-size: 16px;
            font-weight: 500;
            color: #626262;
        }
        span{
            color: black;
            font-weight: 600;
        }
    }
    h1{
        font-family: Lato;
        font-size: 36px;
        font-weight: 800;
        margin:0;
        color: #2D0077;
    }
    h2{
        font-family: Lato;
        font-size: 20px;
        font-weight: 800;
        color: #2D0077;
        margin: 0 5px 0 0;
    }
    hr{
        border-bottom: 1px solid lightgray;
        opacity: 0.6;
    }
    &--Actions{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        gap: 15px;
    }
    button{
        height: 51px;
        padding: 10px;
        border-radius: 4px;
        background: #96C800;
        color: white;
        font-weight: 600;
    }
    button:nth-child(2){
        color: #FF4149;
        background: white;
        border: 1px solid #C8C8C8;
    }
}
.WatFocusPreview{
    .WatFocusTask{
        width: auto;
        position: relative;
    }
    &--ChatIcon{
        position: absolute;
        top: 10px;
        padding: 8px;
        right: 8px;
        border-radius: 9px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #96C800;
        color: white;
        gap: 5px;
        z-index: 99;
        cursor: pointer;
        i{
            font-size: 20px;
        }
        p{
            margin:0 0 -2px 0;
        }
        span{
            background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
            position: absolute;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            top:-8px;
            right: -8px;
            border-radius: 10px;
        }
    }
    &--Chat{
        position: absolute;
        height: 450px;
        width: 400px;
        top: 70px;
        right: 25px;
        z-index: 99;
    }
    
    @media only screen and (max-width: 769px) {
        &--Chat{
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
        }
    }
}
</style>
